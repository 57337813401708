import api from 'api';
import classNames from 'classnames';
import Accordion from 'components/Accordion';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import useModalHandler from 'hooks/useModalHandler';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import ThirdPartyConfirmation from 'scenes/Kyc/EntityManagement/LegalEntityProfile/components/ThirdPartyConfirmation';
import IssueList from 'scenes/Kyc/EntityManagement/components/IssueList';
import { DocumentDataContext } from 'utilities/services/contexts';
import DocumentsPackageTable from '../DocumentsPackageTable';
import AdditionalItemModal from './components/AdditionalItemModal';
import './styles.scss';

function DocumentPackage() {
  const { documentRecord, setDocumentRecord, onRemove } = useContext(DocumentDataContext);
  const [additionalItems, setAdditionalItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const {
    close: closeAdditionalItemModal,
    isOpen: isOpenAdditionalItemModal,
    open: openAdditionalItemModal,
  } = useModalHandler();

  const classes = classNames({
    'ickyc-document-package': true,
    'ickyc-document-package--preview': true,
  });

  const onlyNotPopulatedItems = useCallback(
    data => data?.filter(item => !documentRecord?.items.some(existingItem => existingItem.itemId === item.id)),
    [documentRecord?.items],
  );

  useEffect(() => {
    const fetchadditionalItems = async () => {
      try {
        setIsLoading(true);

        const {
          data: { data },
        } = await api.administration.supportingDocumentPackages.getSupportingDocumentPackagesItems({
          packageId: documentRecord?.supportingDocumentPackageId,
        });

        const finalData = onlyNotPopulatedItems(data);

        setAdditionalItems(finalData);
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchadditionalItems();
  }, [documentRecord?.supportingDocumentPackageId, setAdditionalItems, onlyNotPopulatedItems]);

  return (
    <Accordion title="Document Package" accordionOpen className={classes} onClick={e => e.stopPropagation()}>
      <IssueList list={documentRecord?.issues} />

      <DocumentsPackageTable items={documentRecord?.items} />

      <PrimaryButton disabled={additionalItems.length === 0} onClick={openAdditionalItemModal}>
        + Add Additional Item
      </PrimaryButton>

      {documentRecord?.thirdPartySuggestion && (
        <ThirdPartyConfirmation
          data={documentRecord}
          onDataFetch={setDocumentRecord}
          onRemove={onRemove}
          isSupportingDocument
        />
      )}

      {isOpenAdditionalItemModal && (
        <AdditionalItemModal additionalItems={additionalItems} hideModal={closeAdditionalItemModal} />
      )}
    </Accordion>
  );
}
DocumentPackage.propTypes = {
  documentPackage: PropTypes.shape({}),
};
DocumentPackage.defaultProps = {
  documentPackage: {},
};

export default DocumentPackage;
