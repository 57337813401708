import { CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import PrimaryButton from '../../../../Buttons/PrimaryButton';

const FormModalSubmit = ({ disabled, text, showSpinner, onSubmit }) => {
  const handleClick = useCallback(e => {
    e.stopPropagation();
    onSubmit(e);
  });
  return (
    <PrimaryButton type="submit" disabled={disabled} onClick={handleClick}>
      {text}
      {showSpinner && <CircularProgress />}
    </PrimaryButton>
  );
};
FormModalSubmit.propTypes = {
  disabled: PropTypes.bool,
  text: PropTypes.string,
  showSpinner: PropTypes.bool,
};

FormModalSubmit.defaultProps = {
  disabled: false,
  text: 'Submit',
  showSpinner: false,
};
export default FormModalSubmit;
